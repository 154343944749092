import React, { useRef, useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import HomepagePageFrame from './pages/01_Homepage/HomepagePageFrame'; 
import OurProduct from './pages/02_OurProduct/00_MainProduct/OurProductPageFrame'; 
import Footer from './layout/Footer';
import Navbar from './layout/Navbar';
import Cookie from './layout/Cookie';
import ErrorPage from './pages/00_ErrorPage/ErrorPage';
import Contact from './pages/04_Contact/Contact';
import OurTeam from './pages/03_OurTeam/OurTeam';
import Blog from './pages/03_Blog/Blog';
import BlogArticle from './pages/03_Blog/Features/BlogArticle';
import BlogOverview from './pages/03_Blog/Features/BlogOverview';
import Jobs from './pages/05_Jobs/Jobs';
import JobAd from './pages/05_Jobs/Features/JobAd';
import JobsOverview from './pages/05_Jobs/Features/JobsOverview';
import Imprint from './pages/00_Legal/Imprint';
import PrivacyPolicy from './pages/00_Legal/PrivacyPolicy';
import AnalyticsInjector from './analytics/AnalyticsInjector';

const App = () => {
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [debouncing, setDebouncing] = useState(false);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;

    // Avoid rapid toggling of visibility when near the top
    if (!debouncing) {
      setDebouncing(true);
      setTimeout(() => {
        setVisible(currentScrollPos < prevScrollPos || currentScrollPos <= 0);
        setPrevScrollPos(currentScrollPos);
        setDebouncing(false);
      }, 100); // Debounce timeout of 100ms
    }
  }, [prevScrollPos, debouncing]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // const { trackPageView, trackEvent } = useMatomo();

  // // Track page view
  // const location = useLocation();
  // useEffect(() => {
  //   console.log("LOOOL")
  //   trackPageView({})
  // }, [location])

  return (
    <Router>
      <AnalyticsInjector>
      <div className='flex flex-col'>
        <div className={`fixed w-full z-50 transition-all ease-in-out duration-500 ${visible ? 'top-0' : '-top-full'}`}>
          <Navbar />
        </div>
        <Cookie />
        <div className='pt-[height-of-header]'> {/* Add the exact height of your navbar here */}
          <Routes>
            <Route path='/' element={<HomepagePageFrame section2Ref={section2Ref} section3Ref={section3Ref} section4Ref={section4Ref} section5Ref={section5Ref} />} />
            <Route path='/product' element={<OurProduct />} />
            <Route path='/team' element={<OurTeam />} />
            <Route path='/contact' element={<PaddingWrapper children={<Contact />}/>} />
            <Route path='/blog' element={<Blog />}>
              <Route index={true} element={<PaddingWrapper children={<BlogOverview />}/>} />
              <Route path='article' element={<PaddingWrapper children={<BlogArticle />}/>} />
            </Route>
            <Route path='/jobs' element={<PaddingWrapper children={<Jobs />}/>}>
              <Route index={true} element={<JobsOverview />} />
              <Route path='job' element={<JobAd />} />
            </Route>
            <Route path='/imprint' element={<Imprint />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='*' element={<ErrorPage/>} />
          </Routes>
        </div>
        <Footer />
      </div>
      </AnalyticsInjector>
    </Router>
  );
};

const PaddingWrapper = ({children}) => {
  return (
    <div className='mt-40'>{children}</div>
  )
}

export default App;