import React from 'react'
import TitleSection from './Section1/TitleSection'
import ProductPrinciple from './Section2/ProductPrinciple'
import EngineDetails from './Section3/EngineDetails'
import PrincipleDetails from './Section4/PrincipleDetails'

const OurProduct = () => {
  return (
    <>
      {/* <TitleSection/> */}
      <ProductPrinciple/>
      <EngineDetails/>
      <PrincipleDetails/>
    </>
    )
}

export default OurProduct