import React, {forwardRef, useState } from 'react';

const Section4 = forwardRef((props, ref) => {
    const [currentContentIndex, setCurrentContentIndex] = useState(0);

    const updateContent = (index) => {
      setCurrentContentIndex(index);
    };

    return (
    <div className="thirdSection mb-2 flex flex-col items-center text-gray-700 justify-center relative w-full justify-center md:min-h-screen max-md:h-fit" ref={ref}>

            <div className="mt-6 flex items-center gap-4">
                <h1 className="text-center font-bold lg:text-5xl">Presenting</h1> 
                <img src='img/logos/swap.png' className='h-24'/>
            </div>
            <div className="m-3">
                <h1 className="text-center max-md:text-2xl md:text-3xl font-bold mb-4">AI-based enterprise software selection</h1> 
            </div>

            <nav className="flex bg-[#002169] bg-opacity-10 lg:m-3 border-2 border-white rounded-full md:items-center max-md:items-center max-md:flex-col max-md:rounded-lg max-md:grid max-md:grid-cols-2 max-md:justify-items-center">
                {['Data analysis', 'Monitoring', 'Collaboration', 'Best-practices'].map((label, index) => (
                    <div className="m-3 max-md:m-1" key={index}>
                        <button className={`px-10 py-2 text-sm font-semibold rounded-full max-md:rounded-lg border-2 hover:text-white hover:border-white hover:bg-[#002169] ${currentContentIndex === index ? 'bg-[#002169] text-white' : ''}`} onClick={() => updateContent(index)}>
                            {label}
                        </button>
                    </div>
                ))}
            </nav>

            <div className="flex mb-6 items-center justify-center text-gray-700 relative w-full lg:flex-row max-md:flex-col max-md:px-4 lg:mt-4 mt-4">
                <div className="basis-2/5 text-lg bg-white rounded-[15px] bg-opacity-30" id="textContainer">
                    {contents[currentContentIndex].texts.map((content, index) => (
                        <div className="flex p-4 justify-between rounded-lg items-center hover:bg-white hover:bg-opacity-40" key={index}>
                            <div>
                                <h6 className="uppercase tracking-widest mb-2">{content.heading}</h6>
                                <p className="pb-2">{content.text}</p>
                                <hr className="border-gray-600" />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="pictureContainer mx-4 rounded-lg md:w-1/2 lg:w-2/5 justify-center items-center max-md:mt-4">
                    <img src={contents[currentContentIndex].image()} className='rounded-lg' alt="Dynamic" />
                </div>
            </div>

            <a target='_blank' href='https://www.swap.softwarematch.de/'>
                <button className='mt-8 border border-[#002169] bg-[#002169] hover:scale-[1.05] transition-all bg-opacity-10 hover text-[#002169] p-4 rounded-full text-2xl font-semibold flex items-center'>
                    Learn more about
                    <img src={'/img/logos/swap.png'} alt="SWAP Logo" className="h-16 align-middle ml-2"/>
                </button>
            </a>

        </div>
  )
});

export default Section4;

const contents = [
    {
        texts: [
            { heading: "Open Data Analytics", text: "Explore insights with the sectorlens engine, leveraging AI for comprehensive data analysis. Our platform seamlessly integrates various data sets, enabling swift, data-driven decisions." },
            { heading: "Intelligent Insights", text: "Transform data into actionable insights with our advanced analytics. Predictive models and machine learning enhance strategic planning and operational efficiency." }
        ],
        image: () => require("./Figures/data_analysis.jpg"),
    },
    {
        texts: [
            { heading: "Continuous Monitoring", text: "Monitor data streams 24/7 with real-time alerts. Identify trends and anomalies quickly to maintain operational excellence." },
            { heading: "Proactive Alerts", text: "Stay informed with our alerting system, customizing notifications to preemptively address critical data points and metrics." }
        ],
        image: () => require("./Figures/results_overview.jpg"),
    },
    {
        texts: [
            { heading: "Collaborative Portal", text: "Enhance teamwork with our portal, designed for seamless collaboration and data sharing. Support your data-driven projects with tools that bridge distances." },
            { heading: "Remote Work Optimization", text: "Our platform supports remote collaboration with real-time communication and project management, keeping teams connected and efficient." }
        ],
        image: () => require("./Figures/scheduling_calendar.jpg"),
    },
    {
        texts: [
            { heading: "Best Practices", text: "Leverage proven methodologies with the sectorlens engine, built on industry best practices in analytics. Stay competitive with our robust, evolving platform." },
            { heading: "Innovation Commitment", text: "Benefit from ongoing updates and innovations in AI and analytics, ensuring your projects lead with the latest technologies." }
        ],
        image: () => require("./Figures/req_details.jpg"),
    }
];
