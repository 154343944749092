import React, { forwardRef } from 'react';

const Section2 = forwardRef((props, ref) => {
    return (
    <div className="snap-start py-40 flex text-gray-700 justify-center items-center relative w-full h-fit max-md:h-fit" ref={ref}>
        <div className="px-16 my-24 md:w-3/4 flex justify-center">
            <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="basis-1/3">
                    <h6 className="uppercase text-lg tracking-widest mb-2">Delivering open-data insights</h6>
                    <h1 className="max-md:text-xl text-2xl font-bold mb-4">Empowering Data-Driven Enterprises with Open Data Intelligence</h1>
                    <p className="mb-6 text-lg">Illuminate your strategic and operational decisions without the need to construct your own database. In the vast ocean of the internet lies a wealth of untapped, yet highly relevant data. Sectorlens's mission is to unearth and transform this data into tailored insights for your specific use cases.</p>
                    {/* <!-- <button className="flex text-sm font-semibold hover:underline">
                        Learn More
                        <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6"></path></svg>
                    </button> --> */}
                </div>
                
                <div className="basis-1/2 text-lg mt-8 bg-white rounded-lg bg-opacity-30">
                    <div className="flex pt-6 px-6 justify-between rounded-lg items-center hover:bg-white hover:bg-opacity-40">
                        <div>
                            <h6 className="uppercase tracking-widest mb-2">Data gathering and structuring</h6>
                            <p className="">sectorlens does not need your data, but it might be beneficial. We extract information from public databases and aggregate it, serving as the basis for data structuring.</p>
                            <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                        </div>
                        {/* <!-- <div className="pl-4">
                            <button type="button" className="text-white bg-opacity-0 rounded-full border-2 border-gray-700 font-semibold text-center items-center w-8 h-8 hover:border-white hover:bg-[#721cb0] focus:ring-4 focus:outline-none focus:ring-blue-300">
                                <div className="text-xl text-gray-700 hover:text-white cursor-pointer">
                                    &#x2192;
                                </div>
                            </button>
                        </div> --> */}
                    </div>
                    
                    <div className="flex p-6 justify-between rounded-lg items-center hover:bg-white hover:bg-opacity-40">
                        <div>
                            <h6 className="uppercase tracking-widest">Seamless Data Extraction:</h6>
                            <h6 className="uppercase tracking-widest mb-2">Harnessing AI for Effortless Insights</h6>
                            <p className="pb-2">Harnessing AI solutions, the sectorlens platform simplifies extracting information from diverse sources, with no IT expertise required. Tailored models ensure precise results, meeting company needs seamlessly. Say hello to streamlined extraction with our intuitive solution.</p>
                        </div>
                        {/* <!-- <div className="pl-4">
                            <button type="button" className="text-white bg-opacity-0 rounded-full border-2 border-gray-700 font-semibold text-center items-center w-8 h-8 hover:border-white hover:bg-[#721cb0] focus:ring-4 focus:outline-none focus:ring-blue-300">
                                <div className="text-xl text-gray-700 hover:text-white cursor-pointer">
                                    &#x2192
                                </div>
                            </button>
                        </div> --> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
});

export default Section2