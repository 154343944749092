import React, {useState, useEffect} from 'react'
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
	const [ privacyPolicyContent, setPrivacyPolicyContent ] = useState();
    const [ retries, setRetries ] = useState(0);

    const handleFetchTeam = async () => {
        const res = await fetch(`${process.env.REACT_APP_HCMS_URL}/api/privacy-policy?populate=*&fields=*`, { 
            method: 'GET', 
            headers: new Headers({
                'Authorization': 'Bearer '+ process.env.REACT_APP_HCMS_KEY, 
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
        });

        console.log(res)
        if (res.ok) {
            try {
                const json = await res.json();
                console.log(json.data)
                setPrivacyPolicyContent(json.data);
            } catch(error) {
                console.log(error);
            }
        } else {
            setRetries(prev => prev+1)
        }
    }

    useEffect(() => {handleFetchTeam()}, [])

  	return (
    <div className="container mx-auto p-4 max-w-[500px] lg:max-w-[800px] min-h-screen text-wrap break-words my-32">
		{
			typeof privacyPolicyContent === "undefined" ? <>Loading...</> : (
				<BlocksRenderer content={privacyPolicyContent.attributes.Content} 
				blocks={{
					paragraph: ({ children }) => <p className="text-gray-900 mb-2">{children}</p>,
					heading: ({ children, level }) => {
						switch (level) {
						case 1:
							return <h1 className="text-4xl font-bold mt-8 mb-2">{children}</h1>
						case 2:
							return <h2 className="text-2xl font-bold mt-8 mb-2">{children}</h2>
						case 3:
							return <h3 className="text-xl font-semibold mt-8 mb-2">{children}</h3>
						case 4:
							return <h4 className="text-md font-semibold mt-8 mb-2">{children}</h4>
						default:
							return <h1 className="text-4xl font-bold mt-8 mb-2">{children}</h1>
						}
					},
					code: ({ children }) => <input type='checkbox'></input>,
					link: ({ children, url }) => <Link to={url}>{children}</Link>,
					list: ({ children, format }) => <ul className='list-disc ml-8'>{children}</ul>,
					image: ({ children, image }) => <img className='mt-8 rounded-lg' src={image.url} />,
				}}
				/>
			)
		}
    </div>
  )
}

export default PrivacyPolicy