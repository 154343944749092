import React from 'react'

const RepetitionCard = (props) => {
  return (
    <div className="group border-4 border-white transition-all duration-500 cursor-pointer flex justify-center items-center relative mx-4 rounded-lg overflow-hidden mb-4 max-lg:mb-2 max-lg:w-full  max-lg:hover:w-[300px] max-lg:h-[200px] max-lg:hover:h-[500px] lg:h-[450px] lg:w-[350px] lg:hover:w-[350px]">

        <div className='flex justify-center'>
            <div className='relative rounded-lg flex justify-center'>
                <div className='relative w-full rounded-lg shadow text-center justify-center items-center overflow-hidden flex flex-col absolute transition-all duration-500 opacity-70 group-hover:opacity-0'>

                    {
                        [...Array(20).keys()].map((item, index1) => (
                            <div 
                                style={{
                                    paddingLeft: `${index1 === Math.floor(20 / 2) ? 0 : Math.floor(Math.random() * 150)}px`,
                                }}
                                className='flex'>
                                {
                                    [...Array(30).keys()].map((item, index2) => (
                                        <p 
                                            style={{
                                                opacity: `${Math.floor(Math.random() * 100) + 10}%`,
                                            }}
                                            className={`mr-1 ${props.color} italic`}>{props.title}
                                        </p>
                                    ))
                                } 
                            </div>
                        ))
                    }

                    <div className='absolute inset-0 bg-gray-100 opacity-50'></div> 

                    
                </div>
            </div>
        </div>

        <div className='absolute w-full h-full'>
            <div className={`${props.color} absolute top-1/2 group-hover:top-10 left-1/2 h-full transition-all duration-500 `}>
                <div className='relative flex items-center justify-center'>
                    <div className={`absolute z-[3] text-6xl font-bold ${props.color} italic`}>{props.title}</div>
                    <div className='absolute z-[2] rounded-full bg-gray-100 blur-xl w-44 h-16 group-hover:hidden'></div>
                </div>

            </div>
            
            <div className={`${props.color} absolute opacity-0 group-hover:opacity-100 transition-opacity duration-0 absolute w-full px-4 text-center group-hover:top-20 lg:group-hover:top-22`}>
                {props.text.split("\n").map((item, index) => (
                    <div key={index} className='lg:mt-4' style={{ whiteSpace: 'pre-line' }}>{item}</div>
                ))}
                {props.text2.split("\n").map((item, index) => (
                    <div key={index} className='lg:mt-4' style={{ whiteSpace: 'pre-line' }}>{item}</div>
                ))}
                {props.link && (
                    <div className="flex flex-col items-center mt-4">
                        <a href={props.link} className="flex flex-row items-center bg-white bg-opacity-50 rounded-lg border-2 border-white hover:bg-opacity-30 hover:font-semibold hover:scale-[1.1] transition-all duration-500 p-2" rel="noreferrer" target="_blank">
                            <button className="pr-2 w-32">
                                Try it yourself:
                            </button>
                            <img src={require('./swap.png')} alt="SWAP Logo" className="h-10 align-middle"/>
                        </a>
                    </div>
                )}
            </div>

        </div>
        
    </div>
  )
}

export default RepetitionCard