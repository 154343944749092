import React from 'react'
import { useOutletContext } from 'react-router-dom';
import JobAdRow from '../Components/JobAdRow';

const JobsOverview = () => {
    const {jobs} = useOutletContext();
    
    return (
        <div className='mt-16 pb-16 mx-auto lg:max-w-[1000px] md:px-8 px-4'>
            <div className='w-full flex flex-col items-center'>
                <h1 className='text-4xl text-center font-bold'>Become a part of sectorlens</h1>
                <p className='text-md text-center text-gray-400 w-2/3'>Want to tackle new challenges? We are always looking for talented people who can actively support our team.</p>
            </div>

            <div className="w-full mb-32 pt-32">
                <div className=''>
                <h2 className='text-4xl font-bold mb-8'>Why sectorlens?</h2>
                    <p className='text-justify'>We're seeking individuals who possess a blend of enthusiasm, adaptability, and a genuine eagerness to grow. Our ideal candidates are not necessarily 120% overqualified, but rather individuals who demonstrate a willingness to learn and evolve alongside our vision. Among our five targets, we value those who bring a collaborative spirit and a passion for contributing to something larger than themselves:</p>
                    <ul className="mt-8 list-disc ml-8">
                        <li>Data crunchers</li>
                        <li>Creative react developers</li>
                        <li>Business developers</li>
                        <li>UI/UX experts</li>
                        <li>Full-stack engineers</li>
                    </ul>
                    <p className='mt-8'>If you resonate with our vision and find yourself aligning with our values, we encourage you to apply, even if your specific discipline isn't explicitly mentioned.</p>
                </div>
            </div>
    
            
            <h2 className='text-4xl font-bold mb-8'>Job offerings</h2>
            <div className='space-y-4 mb-32'>
                {
                    typeof jobs !== "undefined" ? jobs.map(job => (
                        <JobAdRow job={job} />
                    )) : <></>
                }
            </div>
    
        </div>
    )
}

export default JobsOverview