import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css'; // Import the CSS file

const ErrorPage = ({ closeMenu }) => {
  return (
    <div className="thirdSection snap-start flex flex-col items-center justify-center text-center text-gray-700 w-full h-screen bg-primary/30 pt-16">
      <div className="flex flex-col items-center justify-center text-center p-10 bg-white rounded-lg shadow-lg">
        <div className="text-2xl font-bold mb-4">Page not found</div>
        <div className="mb-2">The page you are looking for does not exist.</div>
        <div className="mb-6">But check out our core engine:</div>
        <li className="list-none">
          <Link 
            to="/product" 
            className="block no-vibrate"
            onClick={closeMenu}
          >
            <button className="px-10 py-2 text-sm font-semibold rounded-full max-md:rounded-lg border-2 hover:text-white hover:border-white hover:bg-[#721cb0] transition-all duration-300 vibrate">
              <div className="font-semibold text-lg">Product overview</div>
              <span className="text-sm">Get to know our products.</span>
            </button>
          </Link>
        </li>
      </div>
    </div>
  );
}

export default ErrorPage;
