import React, { forwardRef } from 'react';
import RepetitionCard from './components/RepetitionCard';

const Section3 = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="nextSection text-gray-700 pt-32 relative max-md:h-fit md:w-screen">
        <div className="flex justify-center items-center">
          <h1 className="text-center font-bold max-md:text-3xl md:text-5xl px-2">sectorlens products</h1> 
        </div>

        <div className="mt-4 text-center max-md:text-2xl md:text-3xl">Where intelligence meets functionality</div>

        <div className="mx-auto flex flex-row mt-8 items-center justify-center max-lg:flex-col max-lg:w-2/3">
            <RepetitionCard title="swap" color="text-sky-600" text="Software selection processes are inherently complex, especially considering that only 20% of German companies have dedicated IT employees. Misjudged software choices incur significant costs for German SMEs, estimated at around 4 million Euros annually." text2="Meet SWAP: the pioneering solution automates the selection process, offering unprecedented efficiency and accuracy." link="https://www.swap.softwarematch.de/" />
            <RepetitionCard title="valuai" color="text-sky-600" text="Navigating corporate finance intricacies demands precision and expertise. With few companies being equipped with in-house financial specialists, the stakes are high. Miscalculations can lead to substantial losses, estimated at millions of Euros annually for SMEs." text2="Discover valuai: a corporate finance analyzer — your groundbreaking tool for financial assessments." />
            <RepetitionCard title="aisle" color="text-sky-600" text="In the competitive world of sales, securing quality leads is essential for success. With aisle, harnessing the power of AI, we redefine lead generation. By leveraging advanced algorithms, Aisle identifies and cultivates promising leads, ensuring businesses capitalize on every opportunity. " text2="Say farewell to manual prospecting and embrace aisle - your AI-powered partner in sales success." />
        </div>
        
    </div>
  );
});

export default React.memo(Section3);