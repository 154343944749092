import React from 'react';
import "../../../illuminai_style.css";


const Section1 = () => {
    return (
      <div className="nextSection snap-start flex flex-col items-center text-center text-gray-700 w-full h-full pt-25 pt-32">
          <h1 className="max-md:w-[70%] snap-center font-bold mt-4 max-md:text-5xl px-2 text-7xl">
            <img src='/img/logos/logo_horizontal.svg' className='h-32' />
        </h1>
        
        <div className="font-normal mb-24 mt-5 max-md:px-16 max-md:text-xl text-2xl">
            Enlighten your business with AI-powered open data intelligence
        </div>

        <div className="svg-container pb-16 mb-24 lg:block relative" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%"}}>
            <svg className="text-primary" width="44vw" height="30vw" viewBox="0 0 440 340" style={{position: 'relative', alignSelf: 'center'}}>
                <defs>
                    <marker id="arrow" markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,0 L0,6 L9,3 z" fill="currentColor" />
                    </marker>
                </defs>

                <path id="sineWavePath1" d="M 20,320
                                                C 120,320 120,170 220,170
                                                S 320,320 420,320"
                        stroke="currentColor" stroke-width="3" fill="none" marker-end="url(#arrow)"></path>
                <circle cx="20" cy="320" r="10" fill="currentColor" />

                
                <path id="sineWavePath2" d="M 20,20
                                                C 120,20 120,170 220,170
                                                S 320,20 420,20"
                        stroke="currentColor" stroke-width="3" fill="none" marker-end="url(#arrow)"></path>
                <circle cx="20" cy="20" r="10" fill="currentColor" />

                <path id="sineWavePath3" d="M 20,170
                                                C 120,170 120,170 220,170
                                                S 320,170 420,170"
                        stroke="currentColor" stroke-width="3" fill="none" marker-end="url(#arrow)"></path>
                <circle cx="20" cy="170" r="10" fill="currentColor" />


                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="0s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="0s">
                        <mpath href="#sineWavePath1"></mpath>
                    </animateMotion>
                </circle>
                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="0.3333s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="0.3333s">
                        <mpath href="#sineWavePath2"></mpath>
                    </animateMotion>
                </circle>
                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="0.6666s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="0.6666s">
                        <mpath href="#sineWavePath3"></mpath>
                    </animateMotion>
                </circle>


                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="1s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="1s">
                        <mpath href="#sineWavePath1"></mpath>
                    </animateMotion>
                </circle>
                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="1.3333s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="1.3333s">
                        <mpath href="#sineWavePath2"></mpath>
                    </animateMotion>
                </circle>
                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="1.6666s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="1.6666s">
                        <mpath href="#sineWavePath3"></mpath>
                    </animateMotion>
                </circle>


                <circle r="6" fill="currentColor" visibility="hidden"> 
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="2s" /> 
                    <animateMotion dur="4s" repeatCount="indefinite" begin="2s">
                        <mpath href="#sineWavePath1"></mpath>
                    </animateMotion>
                </circle>
                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="2.3333s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="2.3333s">
                        <mpath href="#sineWavePath2"></mpath>
                    </animateMotion>
                </circle>
                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="2.6666s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="2.6666s">
                        <mpath href="#sineWavePath3"></mpath>
                    </animateMotion>
                </circle>


                <circle r="6" fill="currentColor" visibility="hidden"> 
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="3s" /> 
                    <animateMotion dur="4s" repeatCount="indefinite" begin="3s">
                        <mpath href="#sineWavePath1"></mpath>
                    </animateMotion>
                </circle>
                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="3.3333s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="3.3333s">
                        <mpath href="#sineWavePath2"></mpath>
                    </animateMotion>
                </circle>
                <circle r="6" fill="currentColor" visibility="hidden">
                    <set attributeName="visibility" attributeType="CSS" to="visible" begin="3.6666s" />
                    <animateMotion dur="4s" repeatCount="indefinite" begin="3.6666s">
                        <mpath href="#sineWavePath3"></mpath>
                    </animateMotion>
                </circle>
            </svg>
            
            {/* <LabelText text={"Open website data"} fontSize={"1.4vw"} top={"-1vw"} right={"50%"} marginRight={"22vw"} textAlign={"right"} lineHeight={"1.2"} /> */}
            <div className="font-bold absolute" style={{top: "-1vw", right: "50%", marginRight: "21vw", textAlign: "right", fontSize: "max(1.4vw, 9px)", lineHeight: "1.2"}}>
                <div>Unstructred</div>
                <div>and open</div>
                <div>web-data</div>
            </div>

            <div className="font-bold absolute" style={{top: "12.5vw", right: "50%", marginRight: "21vw", textAlign: "right", fontSize: "max(1.4vw, 9px)", lineHeight: "1.2"}}>
                <div>Internal</div>
                <div>Company</div>
                <div>Insights</div>
            </div>
            <div className="text-1xl font-bold absolute" style={{top: "26vw", right: "50%", marginRight: "21vw", textAlign: "right", fontSize: "max(1.4vw, 9px)", lineHeight: "1.2"}}>
                <div>Self-learning</div>
                <div>Knowledge</div>
                <div>Basis</div>
            </div>

            <div className="text-1xl font-bold absolute" style={{top: "-1vw", left: "50%", marginLeft: "22vw", textAlign: "left", fontSize: "max(1.4vw, 9px)", lineHeight: "1.2"}}>
                <div>Automated</div>
                <div>Customer</div>
                <div>Analyses</div>
            </div>
            <div className="text-1xl font-bold absolute" style={{top: "12.5vw", left: "50%", marginLeft: "22vw", textAlign: "left", fontSize: "max(1.4vw, 9px)", lineHeight: "1.2"}}>
                <div>Automated</div>
                <div>Competitor</div>
                <div>Analyses</div>
            </div>
            <div className="text-1xl font-bold absolute" style={{top: "26vw", left: "50%", marginLeft: "22vw", textAlign: "left", fontSize: "max(1.4vw, 9px)", lineHeight: "1.2"}}>
                <div>Automated</div>
                <div>Supplier</div>
                <div>Analyses</div>
            </div>
        </div>

    </div>
  )
}

export default Section1