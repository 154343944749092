import React from 'react';
import Section1 from './Sections/Section1/Section1';
import Section2 from './Sections/Section2/Section2';
import Section3 from './Sections/Section3/Section3';
import Section4 from './Sections/Section4/Section4';
import Section5 from './Sections/Section5/Section5';
import ContactSection from './Sections/ContactSection';

const HomepagePageFrame = ({ section2Ref, section3Ref, section4Ref, section5Ref }) => {
  return (
    <>
      <Section1 />
      <Section2 ref={section2Ref} />
      <Section3 ref={section3Ref} />
      <Section4 ref={section4Ref} />
      {/* <Section5 ref={section5Ref} /> */}
      <ContactSection />
    </>
  );
}

export default HomepagePageFrame;
