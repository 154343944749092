import React, { useState, useEffect } from 'react';

const TeamGrid = () => {
    const [ teammembers, setTeammembers ] = useState();
    const [ retries, setRetries ] = useState(0);

    const handleFetchTeam = async () => {
        const res = await fetch(`${process.env.REACT_APP_HCMS_URL}/api/teammembers?sort=Order:asc&populate=*&fields=*`, { 
            method: 'GET', 
            headers: new Headers({
                'Authorization': 'Bearer '+ process.env.REACT_APP_HCMS_KEY, 
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
        });

        if (res.ok) {
            try {
                const json = await res.json();
                setTeammembers(json.data);
            } catch(error) {
                console.log(error);
            }
        } else {
            setRetries(prev => prev+1)
        }
    }

    useEffect(() => {handleFetchTeam()}, [])
    return (
        <div className='mt-32'>
            <div className='w-full flex flex-col items-center'>
                <h1 className='text-4xl text-center font-bold'>The team behind Sectorlens</h1>
                <p className='text-md text-center text-gray-400 w-2/3'>Our team consists of experts from different backgrounds. Get to know our team and the people behind Sectorlens.</p>
            </div>
            <div className="w-fit mx-auto mt-8 grid md:grid-cols-3 grid-cols-1 max-sm:space-y-8 gap-2 my-6">
                {
                    typeof teammembers === "undefined" ? (<>Loading...</>) : (
                        <>
                            {
                                teammembers.map(person => {
                                    if (person.attributes.Order != -1) {
                                        return (
                                            <div className="flex-col group">
                        
                                                <div className="relative mx-auto w-full md:w-72 md:h-72 flex items-end">
                                                    <div className="absolute h-44 group-hover:h-72 transition-all duration-500 w-full bg-[#f7ce46] rounded-lg"></div>
                                                    <img className={`object-cover rotate-0 w-full grayscale transition-all duration-500 brightness-125 group-hover:brightness-150 rounded-b-lg`} src={process.env.REACT_APP_HCMS_URL+person.attributes.Picture.data.attributes.url} alt={person.attributes.Picture.data.attributes.url} />
                                                </div>
                        
                                                <p className="mt-2 text-center text-lg font-semibold text-gray-500 transition-all duration-500 group-hover:text-[#f7ce46]">{person.attributes.Name}</p>
                                                <p className="text-center italic text-gray-500">{person.attributes.Position}</p>
                        
                                            </div>
                        
                                        )
                                    }
                                })
                            }
                        <div className="flex-col group">

                            <div className="relative mx-auto w-full md:w-72 md:h-72 flex items-end">
                                <div className="absolute h-44 group-hover:h-72 transition-all duration-500 w-full bg-[#f7ce46] rounded-lg"></div>
                                <img className="mt-8 object-cover w-full grayscale transition-all duration-500 brightness-125 group-hover:brightness-150 rounded-b-lg mt-16" src={process.env.REACT_APP_HCMS_URL+teammembers.filter(i => i.attributes.Order == -1)[0].attributes.Picture.data.attributes.url} alt="nn" />
                            </div>

                            <p className="mt-2 text-center text-lg font-semibold text-gray-500 transition-all duration-500 group-hover:text-[#f7ce46]">You (?)</p>
                            <div className="flex justify-center">
                                <a href="#open-positions" className="underline underline-offset-2 hover:underline-offset-4 text-center italic text-gray-500">Join our team</a>
                            </div>

                        </div>
                        </>
                    )
                }

            </div>
        </div>
    )
}

export default TeamGrid