import React, { useState, useEffect } from 'react';
import TeamGrid from './features/TeamGrid';
import { Link } from 'react-router-dom';

const OurTeam = () => {
  const fullCodeString = `import React, { useState } from 'react';
  import { animated, useSpring } from 'react-spring/konva';
  
  const vendors = [
    { id: 1, name: 'Vendor A', position: 100 },{ id: 2, name: 'Vendor B', position: 200 },
  ];
  
  export const VendorDisplay = () => {
    const [selectedVendor, setSelectedVendor] = useState(vendors[0]);
    
    const { x } = useSpring({
      from: { x: 0 },
      to: { x: selectedVendor.position },
    });
  
    return (
      <>
        <animated.Rect x={x} />
        {vendors.map(vendor => (
          <button key={vendor.id} onClick={() => setSelectedVendor(vendor)}>
            {vendor.name}
          </button>
        ))}
      </>
    );
  };`;

  const [codeString, setCodeString] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      const length = codeString.length;
      if (length < fullCodeString.length) {
        setCodeString(fullCodeString.substring(0, length + 1));
      }
    }, 100); // Adjust typing speed here

    return () => clearTimeout(timer);
  }, [codeString, fullCodeString]);

  return (
    <div>
      <TeamGrid />

      <div className="flex max-md:flex-col md:flex-row mt-32 text-gray-700 w-full mb-32">
        <div className='h-full flex items-center justify-center'>
          <div className='flex flex-col md:mr-12 items-start justify-between space-y-4 max-md:w-11/12 md:w-4/12 max-md:pb-12 pl-10'>
            <h2 className="text-2xl font-bold">It's not just work</h2>
            <p className='text-justify'>We're seeking individuals who possess a blend of enthusiasm, adaptability, and a genuine eagerness to grow. Our ideal candidates are not necessarily 120% overqualified, but rather individuals who demonstrate a willingness to learn and evolve alongside our vision. Among our five targets, we value those who bring a collaborative spirit and a passion for contributing to something larger than themselves:</p>
            <ul className="list-disc">
              <li>Data crunchers</li>
              <li>Creative react developers</li>
              <li>Business developers</li>
              <li>UI/UX experts</li>
              <li>Full-stack engineers</li>
            </ul>
            <p>If you resonate with our vision and find yourself aligning with our values, we encourage you to apply, even if your specific discipline isn't explicitly mentioned.</p>
            <div className='w-full flex justify-center'>
              <Link to="/jobs">
                <button className="px-10 py-2 font-semibold rounded-full border-2 bg-[#002169] bg-opacity-10 hover:bg-opacity-100 hover:border-white hover:bg-[#002169] text-black hover:text-white transition-all duration-300">
                  Join the team
                </button>
              </Link>
            </div>
          </div>
          <div className="max-md:hidden max-md:w-11/12 md:w-5/12 bg-gray-800 text-white text-sm p-4 font-mono rounded-lg overflow-auto h-fit">
            <pre>{codeString}</pre>
          </div>

        </div>

      </div>
    </div>
  );
};

export default OurTeam;



// // OurTeam.js
// import React, { useState, useEffect } from 'react';
// import AceEditor from 'react-ace';
// import 'ace-builds/src-noconflict/mode-javascript';
// import 'ace-builds/src-noconflict/theme-monokai';
// import 'ace-builds/src-noconflict/ext-language_tools';
// import ErrorBoundary from '/Users/MPanzer/Documents/Code/illuminai-websites/illuminai-react/src/features/03_OurTeam/ErrorBoundary.jsx'; // make sure this path is correct

// const OurTeam = () => {
//   const software_vendor = 'react-spring/konva';
//   const fullCodeString = `import { animated, useSpring } from '${software_vendor}';

// export const MyComponent = () => {
//   const { x } = useSpring({
//     from: { x: 0 },
//     to: { x: 1 },
//   });

//   return <animated.Rect x={x} />;
// };`;

//   const [codeString, setCodeString] = useState('');

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       const length = codeString.length;
//       if (length < fullCodeString.length) {
//         setCodeString(fullCodeString.substring(0, length + 1));
//       }
//     }, 30);

//     return () => clearTimeout(timer);
//   }, [codeString, fullCodeString]);

//   return (
//     <div className="nextSection snap-start flex flex-row justify-center items-center text-gray-700 w-full h-screen mb-2 pt-32">
//       <div className='flex flex-col items-start justify-between space-y-4 w-5/12 pl-10'>
//         <h2 className="text-2xl font-bold">It's not just for web</h2>
//         <p>Choose from our five targets:</p>
//         <ul className="list-disc">
//           <li>Data cruncher</li>
//           <li>React developer</li>
//           <li>Business developer</li>
//           <li>UI/UX expert</li>
//           <li>Full-stack engineer</li>
//         </ul>
//         <p>Missing a target you want? Request we add it or create it yourself with our advanced API usage.</p>
//         <a href="https://www.linkedin.com/company/illuminai" target="_blank" rel="noopener noreferrer">
//           <button className="px-10 py-2 font-semibold rounded-full border-2 bg-[#721cb0] bg-opacity-10 hover:bg-opacity-100 hover:border-white hover:bg-[#721cb0] text-black hover:text-white transition-all duration-300">
//             Learn more about targets
//           </button>
//         </a>
//       </div>
//       <ErrorBoundary>
//         <div className="w-1/3">
//           <AceEditor
//             mode="javascript"
//             theme="monokai"
//             value={codeString}
//             name="codeEditor"
//             editorProps={{ $blockScrolling: true }}
//             fontSize={14}
//             showPrintMargin={true}
//             showGutter={true}
//             highlightActiveLine={true}
//             setOptions={{
//               enableBasicAutocompletion: true,
//               enableLiveAutocompletion: true,
//               enableSnippets: false,
//               showLineNumbers: true,
//               tabSize: 2,
//             }}
//             style={{ width: '100%', height: '300px' }}
//           />
//         </div>
//       </ErrorBoundary>
//     </div>
//   );
// };

// export default OurTeam;
