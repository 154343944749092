import React from 'react';

const EngineDetails = () => {
  return (
    <div className='h-fit py-12 nextSection text-gray-700 flex flex-col items-center justify-center p-4 my-32'>
      <div className='mb-4 text-center'>
        <h2 className='font-semibold text-3xl'>Our  <span className="text-transparent font-semibold text-3xl bg-clip-text bg-gradient-to-r to-[#57e3ff] from-[#a730fc] pr-2">core engines</span></h2>
      </div>
      <div className='grid border-t border-gray-700 pt-4 max-md:grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl w-full'>
        <div className="bg-white bg-opacity-90 shadow-lg rounded-lg p-8 border-2 border-gray-200 flex flex-col">
          <h1 className="text-xl font-bold text-gray-600">Sectorlens</h1>
          <h2 className="text-base font-bold">Short and efficient crawling</h2>
          <p className="mt-4">
            Optimize your process workflows with our base crawling system, specifically designed for high efficiency and rapid data retrieval with minimal processing overhead. The base version is perfect for applications where speed and efficiency are paramount, such as quick market analysis tasks, preliminary saled lead identification, or rough company classification tasks.          </p>
          <ul className="list-disc pl-8 mt-6">
            <li><strong>Response Time:</strong> Lightning-fast insights. Get results in a few seconds.</li>
            <li><strong>Data Efficiency:</strong> High computation efficiency with combined intelligent and heuristics approaches</li>
            <li><strong>Cost:</strong> Low operational cost for easy tasks and prototyping applications</li>
          </ul>
          <div className='flex w-full justify-center pt-4 mt-auto'>
            <a href="https://www.swap.softwarematch.de/" className="flex flex-row items-center border border-[#002169] bg-[#002169]/30 rounded-full p-4 border border-white hover:scale-[1.1] transition-all duration-500" target="_blank" rel="noreferrer">
                <button className="flex-row font-semibold text-base text-[#002169] pr-2">
                    Try it in
                </button>
                <img src={'./img/swap.png'} alt="SWAP Logo" className="h-10 align-middle"/>
            </a>
          </div>
        </div>

        <div className="bg-white bg-opacity-90 shadow-lg rounded-lg p-8 border-2 border-gray-200 flex flex-col">
          <h1 className="text-xl font-bold text-gray-600">Sectorlens - pro model</h1>
          <h2 className="text-base font-bold"> Deeper insights for sophisticated tasks</h2>
          <p className="mt-4 text-justify">
          Our biggest engine. Experience the power of advanced, state-of-the-art large language models with our pro engine. It is designed to collect, structure, and synthesize vast amounts of web and internal information, connecting disparate data points into cohesive, actionable insights with deep analytical capabilities.
          </p>
          <p className="mt-4 text-justify">
          Analyze your competitors, entire markets, or find your best-fit software products, all with maximum traceability.
          </p>

          <ul className="list-disc pl-8 mt-6">
            <li><strong>Processing Power:</strong> High capacity to handle complex tasks</li>
            <li><strong>Data Integration:</strong> Offers extensive data integration options. (PDF, .zip, .txt, .csv, urls)</li>
            <li><strong>Cost:</strong> Reflects the premium capabilities with a higher investment level</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default EngineDetails;
